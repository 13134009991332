document.addEventListener('DOMContentLoaded', () => {
  // Autorise uniquement les chiffres et affiche un message si la condition de 5 chiffres n'ait pas respecté sur l'input code postale
  const input = document.querySelector('input[name="store_locator[postalCode]"]');

  if (input) {
    const errorMessage = getErrorMessage(input);

    input.addEventListener('input', () => validate(input, errorMessage));
  }

  // Au submit du formulaire, vérifie qu'il est valide
  let form = document.querySelector('.form--store-locator');

  if (!(form instanceof HTMLFormElement)) {
    form = form.querySelector('form');
  }

  if (form instanceof HTMLFormElement) {
    form.addEventListener('submit', e => manageSubmit(e, form));
  }
});

const getErrorMessage = input => {
  const errorMessage = document.createElement('span');

  errorMessage.classList.add('error-message-cp');
  errorMessage.innerText = 'Le code postal doit contenir exactement 5 chiffres.';
  errorMessage.style.display = 'none';

  input.parentNode.appendChild(errorMessage);

  return errorMessage;
}

const validate = (input, errorMessage) => {
  const form = input.closest('form');

  input.value = input.value.replace(/\D/g, '');

  if (5 === input.value.length) {
    input.style.borderColor = '';
    errorMessage.style.display = 'none';
    form.removeAttribute('data-invalid');
  } else {
    input.style.borderColor = 'red';
    errorMessage.style.display = 'block';
    form.dataset.invalid = '';
  }
};

const manageSubmit = (e, form) => {
  e.preventDefault();

  if (!form.hasAttribute('data-invalid')) {
    if (form.hasAttribute('data-block')) {
      form.submit();
    } else {
      form.dispatchEvent(new Event('validSubmit'));
    }
  }
}
